import * as actionTypes from "../actions/types";

const initialState = {
  sales: [],
  resales: [],
  customers: [],
  searchedCustomers: [],
  products: [],
  searchedResales: [],
  permissions: {
    sales: [],
    customers: [],
    resales: [],
  },
  loading: false,
  itemLoading: false,
  errors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SALES_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionTypes.ITEM_LOADING:
      return {
        ...state,
        itemLoading: true,
        errors: null,
      };
    case actionTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionTypes.SET_SALES:
      return {
        ...state,
        loading: false,
        sales: action.sales,
      };
    case actionTypes.SET_RESALES:
      return {
        ...state,
        loading: false,
        resales: action.resales,
      };
    case actionTypes.SET_RESALES_INV_LIST:
      return {
        ...state,
        loading: false,
        searchedResales: action.searchedResales,
      };
    case actionTypes.SET_CUSTOMERS:
      return {
        ...state,
        loading: false,
        customers: action.customers,
      };
    case actionTypes.ALL_CUSTOMERS_SEARCH:
      return {
        ...state,
        searchedCustomers: action.customers,
        itemLoading: false,
      };
    case actionTypes.SALE_PRODUCT_SEARCH:
      const productsFixedNumbers = action.products.map((prod) => {
        return {
          ...prod,
          price: parseFloat(prod.price).toFixed(2),
          min_price: parseFloat(prod.min_price).toFixed(2),
          jprice: parseFloat(prod.jprice).toFixed(2),
          min_jprice: parseFloat(prod.min_jprice).toFixed(2),
          res_price: parseFloat(prod.res_price).toFixed(2),
          lastprice: parseFloat(prod.lastprice).toFixed(2),
          jlastprice: parseFloat(prod.jlastprice).toFixed(2),
          quantity: parseInt(prod.quantity).toFixed(2),
          tax_value: parseFloat(prod.tax_value ? prod.tax_value : 0).toFixed(2),
        };
      });

      return {
        ...state,
        products: productsFixedNumbers,
        itemLoading: false,
      };
    case actionTypes.SALES_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };

    case actionTypes.SET_CUSTOMER_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          customers: action.customerPermissions,
        },
      };
    case actionTypes.SET_SALES_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          sales: action.salesPermissions,
        },
      };
    case actionTypes.SET_RESALES_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          resales: action.resalesPermissions,
        },
      };
    default:
      return state;
  }
};
