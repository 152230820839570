import React, { useContext } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import themeContext from "../../../../contexts/theme/themeContext";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TopCards = () => {
  const { gradient_primary } = useContext(themeContext);
  const profile = useSelector((state) => state.profile);

  const { t } = useTranslation();
  const cardsData = [
    {
      title: t("common:account"),
      unit: t("common:SR"),
      bg: gradient_primary,
      icon: MonetizationOnOutlinedIcon,
      value: profile.caisse,
      // permission: "showownsale",
    },
    {
      title: t("common:sales"),
      unit: t("common:SR"),
      bg: "bg-orange-gradient",
      icon: LocalOfferOutlinedIcon,
      value: profile.sales,
      // permission: "showownsale",
    },
  ];

  // const cards = cardsData.filter((c) =>
  //   permissions.some((per) => per === c.permission)
  // );

  return (
    <Grid container spacing={3} className="py-2">
      {cardsData.map((card, i) => (
        <SingleCard
          key={i}
          title={card.title}
          unit={card.unit}
          bg={card.bg}
          Icon={card.icon}
          value={card.value}
        />
      ))}
    </Grid>
  );
};

export default TopCards;

const SingleCard = ({ title, unit, bg, Icon, value }) => {
  return (
    <Grid item xs={6}>
      <Paper elevation={6} className="p">
        <Grid container>
          <Grid item md={11} sm={10} xs={8}>
            <Typography variant="caption" color="textPrimary">
              {title}
            </Typography>
          </Grid>
          <Grid item md={1} sm={2} xs={4}>
            <Paper
              className={`${bg} p`}
              style={{ marginTop: -22, maxWidth: 40 }}
              elevation={6}
              square
            >
              <Typography variant="h3" className="text-light">
                <Icon fontSize="inherit" style={{ marginTop: 5 }} />
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} className="xp">
            <Typography variant="h5" color="textPrimary">
              {value} {unit}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
