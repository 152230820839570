export default {
  STORE_NUMBER: 2,
  cashCustomerPOS: {
    id: 1,
    name: "عميل نقدي",
    name_en: "CASH CUSTOMER",
    salesman: 1,
    salesman_name: "Admin",
  },
  stores: [],
};
