import React, { Fragment } from "react";
import AppBar from "./AppBar";
// import Footer from "./Footer";

const Layout = ({ children, dense }) => {
  return (
    <Fragment>
      <AppBar dense={dense} />
      <main>{children}</main>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default Layout;
