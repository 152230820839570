import * as actionTypes from "./types";
import axios from "../../util/axiosPublic";
import { axios as axiosAuth } from "../../util/axiosAuth";
import { getCookieToken } from "../../util/getCookieToken";
import { setAuthHeader } from "../../util/axiosAuth";
import { eraseCookie } from "../../util/cookies";

const loading = () => {
  return {
    type: actionTypes.PROFILE_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.PROFILE_ERRORS,
    errors: err && err.data ? err.data : err,
  };
};
export const getProfile = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const auth = getCookieToken();

      const { token, expired, token_origine } = auth;
      const profile = await axios.get("/user/profile", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const settings = await axios.get("/setting?per-page=50", {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: actionTypes.SET_SETTINGS,
        settings: settings.data.setting,
      });
      dispatch({
        type: actionTypes.LOGIN,
        token: token,
        expired: expired,
        token_origine: token_origine,
      });
      setAuthHeader(token, (cb) => {
        dispatch({
          type: actionTypes.GET_PROFILE,
          user_id: profile.data.user_id,
          user_name: profile.data.user_name,
          stores: profile.data.store,
          roles: profile.data.roles,
          permissions: profile.data.permissions,
          caisse: profile.data.caisse,
          sales: profile.data.sales,
        });
      });
    } catch (err) {
      setAuthHeader(null, (cb) => {
        eraseCookie("auth");
      });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const refreshProfileSilently = () => {
  return async (dispatch) => {
    try {
      const profile = await axiosAuth.get("/user/profile");

      dispatch({
        type: actionTypes.GET_PROFILE,
        user_id: profile.data.user_id,
        user_name: profile.data.user_name,
        stores: profile.data.store,
        roles: profile.data.roles,
        permissions: profile.data.permissions,
        caisse: profile.data.caisse,
        sales: profile.data.sales,
      });
    } catch (err) {
      // setAuthHeader(null, (cb) => {
      //   eraseCookie("auth");
      // });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
