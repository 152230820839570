import * as actionsTypes from "../actions/types";

const initialState = {
  token: "",
  expired: "",
  token_origine: "",
  loading: false,
  errors: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.AUTH_LOADING:
      return {
        ...state,
        loading: true,
        errors: null
      };
    case actionsTypes.LOGIN:
      return {
        ...state,
        loading: false,
        token: action.token,
        expired: action.expired,
        token_origine: action.token_origine
      };
    case actionsTypes.AUTH_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors
      };
    case actionsTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};
