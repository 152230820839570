import * as actionTypes from "../actions/types";

const initialState = {
  open: false,
  variant: "warning",
  message: "مرحبا",
  vertical: "top",
  horizontal: "right",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIRE_SNAKEBAR:
      return {
        open: true,
        variant: action.variant,
        message: action.message,
        vertical: action.vertical ? action.vertical : "top",
        horizontal: action.horizontal ? action.horizontal : "right",
      };
    case actionTypes.CLEAR_SNAKEBAR:
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
};
