import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: "ar-AR",
    fallbackLng: "ar-AR",
    ns: [
      "common",
      "stores",
      "purchases",
      "sales",
      "settings",
      "expenses",
      "reports",
      "offers",
      "pos",
    ],
    defaultNS: "common",
    // debug: true,
    // interpolation: {
    //   escapeValue: false,
    // },
    react: {
      wait: true,
    },
  });

export default i18n;
