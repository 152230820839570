import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ThemeState from "./contexts/theme/ThemeState";
import { Provider } from "react-redux";
import "./index.css";
import store from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import "./i18n";
import Loader from "./views/Loader";

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <CssBaseline />
    <Suspense fallback={<Loader text="Loading" />}>
      <ThemeState>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeState>
    </Suspense>
  </Provider>,
  // </React.StrictMode>
  document.querySelector("#root")
);
