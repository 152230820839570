import React from "react";
import { Box, Link } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

const Social = () => {
  return (
    <div className="flex hor flex-justify-center">
      <Box marginX={1}>
        <Link href="https://twitter.com/wakaed" target="_blank">
          <TwitterIcon className="text-light hoverable-op" />
        </Link>
      </Box>
      <Box marginX={1}>
        <Link href="https://instagram.com/wakaed" target="_blank">
          <InstagramIcon className="text-light hoverable-op" />
        </Link>
      </Box>
      <Box marginX={1}>
        <Link href="https://www.facebook.com/wakaed" target="_blank">
          <FacebookIcon className="text-light hoverable-op" />
        </Link>
      </Box>
    </div>
  );
};

export default Social;
