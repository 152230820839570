import React, { useContext } from "react";
import { Grid, Typography, Zoom } from "@material-ui/core";
import SquareItem from "../../../../components/MenusItems/SquareItem";
import { data } from "./menuData";
import themeContext from "../../../../contexts/theme/themeContext";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const MainMenu = () => {
  const { gradient_primary } = useContext(themeContext);
  const { t } = useTranslation();

  const permissions = useSelector((state) => state.profile.permissions);

  const routes = data.filter((route) =>
    permissions.some((per) => per === route.permission)
  );

  return (
    <Grid container spacing={3}>
      {routes.map((m) => (
        <Zoom in style={{ transitionDelay: m.id * 50 }} key={m.id}>
          <Grid item sm={6} xs={12}>
            <SquareItem
              title={
                <Typography variant="h2" className="py-1">
                  {t("common:" + m.title)}
                </Typography>
              }
              icon={m.icon}
              link={m.link}
              bg={gradient_primary}
            />
          </Grid>
        </Zoom>
      ))}
    </Grid>
  );
};

export default MainMenu;
