import React from "react";
import Home from "./views/Home/Home";
import Login from "./views/Auth/Login";
import Page404 from "./views/404";

const POS = React.lazy(() => import("./views/POS/POS"));
const POSReturns = React.lazy(() => import("./views/POS/POSReturns"));

export const authRoutes = [
  {
    path: "/",
    component: Home,
    configs: { exact: true },
  },

  {
    path: "/pos",
    component: POS,
    configs: { exact: true },
  },
  {
    path: "/pos/return",
    component: POSReturns,
  },
  {
    path: "*",
    component: Page404,
  },
];
export const publicRoutes = [
  {
    path: "*",
    component: Login,
  },
];
