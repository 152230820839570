import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";

const PageSpinner = ({ color, styles, classes }) => {
  const _classes = useStyles();
  return (
    <CircularProgress
      size="3rem"
      color="inherit"
      className={`${
        color === "light" ? "text-light" : _classes.root
      } ${classes}`}
      style={styles}
    />
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
  },
}));

export default PageSpinner;
