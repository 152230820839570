import { createStore, applyMiddleware, compose } from "redux";
import rootReducers from "./reducers";
import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//   trace: true,
//   traceLimit: 25,
// }) || compose;
const middlewares = [thunk];

const store = createStore(
  rootReducers,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
