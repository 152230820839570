import React from "react";
import DeveloperBoardRoundedIcon from "@material-ui/icons/DeveloperBoardRounded";
import ScreenShareRoundedIcon from "@material-ui/icons/ScreenShareRounded";

export const data = [
  {
    id: 1,
    title: "POS",
    icon: <DeveloperBoardRoundedIcon style={{ fontSize: 50, color: "#eee" }} />,
    link: "/pos",
    permission: "managesales",
  },
  {
    id: 2,
    title: "pos_transfer",
    icon: <ScreenShareRoundedIcon style={{ fontSize: 50, color: "#eee" }} />,
    link: "#",
    permission: "managesales",
  },
];
