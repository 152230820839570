import * as actionTypes from "../actions/types";

const initialState = {
  settings: [],
  users: [],
  usergroups: [],
  userstores: [],
  banks: [],
  employees: [],
  salesman: [],
  sitePermission: [],
  permissions: {
    banks: [],
    employees: [],
    users: [],
    usergroups: [],
  },
  loading: false,
  errors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionTypes.SET_SETTINGS:
      const filteredSettings = filterSettings(action.settings);
      return {
        ...state,
        loading: false,
        settings: filteredSettings,
      };
    case actionTypes.EDIT_SETTING:
      let updatedSettings;
      if (action.setType === "generals") {
        updatedSettings = editSettingOf(state.settings.generals, action);
      } else if (action.setType === "appearances") {
        updatedSettings = editSettingOf(state.settings.appearances, action);
      }
      return {
        ...state,
        loading: false,
        settings: { ...state.settings, [action.setType]: updatedSettings },
      };
    case actionTypes.SET_USERS:
      return {
        ...state,
        loading: false,
        users: action.users,
      };
    case actionTypes.SET_USER_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          users: action.userPermissions,
        },
      };
    case actionTypes.SET_USERGROUPS:
      return {
        ...state,
        loading: false,
        usergroups: action.usergroups,
      };
    case actionTypes.SET_USERSTORE:
      return {
        ...state,
        loading: false,
        userstores: action.userstores,
      };
    case actionTypes.SET_USERGROUP_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          usergroups: action.usergroupPermissions,
        },
      };
    case actionTypes.SET_EMPLOYEE:
      return {
        ...state,
        loading: false,
        employees: action.employees,
      };
    case actionTypes.SET_EMPLOYEES_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          employees: action.employeePermissions,
        },
      };
    case actionTypes.SET_BANKS:
      return {
        ...state,
        loading: false,
        banks: action.banks,
      };
    case actionTypes.SET_BANK_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          banks: action.bankPermissions,
        },
      };
    case actionTypes.SET_SALESMAN:
      return {
        ...state,
        loading: false,
        salesman: action.salesman,
      };
    case actionTypes.SET_PERMISSIONS:
      return {
        ...state,
        loading: false,
        sitePermission: action.permissions,
      };
    case actionTypes.SETTINGS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionTypes.UNLOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

//Helpers
const filterSettings = (settings) => {
  let generals = [];
  let appearances = [];
  for (let key of settings) {
    if (
      key.name === "sitename" ||
      key.name === "company" ||
      key.name === "mobile" ||
      key.name === "phone" ||
      key.name === "fax" ||
      key.name === "domain" ||
      key.name === "country" ||
      key.name === "city" ||
      key.name === "address" ||
      key.name === "contactemail" ||
      key.name === "bankaccount"
    ) {
      generals.push(key);
    }
    if (
      key.name === "maintenance" ||
      key.name === "template" ||
      key.name === "verify_ip" ||
      key.name === "frontendlanguage"
    ) {
      appearances.push(key);
    }
  }
  generals
    .sort((a, b) => (a.name > b.name ? -1 : a.name < b.name ? 1 : 0))
    .push(settings.find((set) => set.name === "logo"));

  return { generals, appearances };
};
const editSettingOf = (state, action) => {
  return state.map((set) =>
    set.name === action.setting.name ? action.setting : set
  );
};
