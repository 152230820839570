import React, { useState, useEffect, useCallback, useContext } from "react";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Logo from "../../assets/images/logo-1.png";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../components/UI/Inputs/Input";
import { AppBar, Container } from "@material-ui/core";
import PhoneAndroidOutlinedIcon from "@material-ui/icons/PhoneAndroidOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import Social from "../../components/UI/SocialIconsList/Social";
import * as actions from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import ButtonSpinner from "../../components/UI/Spinners/ButtonSpinner";
import { useTranslation } from "react-i18next";
import themeContext from "../../contexts/theme/themeContext";
import SwitchButtons from "../../components/UI/Buttons/SwitchButtons/SwitchButtons";
import global from "../../util/global";

const langBtns = [
  { name: "ar-AR", content: "ar" },
  { name: "en-US", content: "en" },
];

const Login = ({ history }) => {
  const classes = useStyles();
  const [state, setstate] = useState({
    username: {
      value: "",
      validity: false,
    },
    password: {
      value: "",
      validity: false,
    },
    remember: {
      value: false,
      validity: true,
    },
  });

  const authStore = useSelector((state) => state.auth);
  const { isAuth } = useSelector((state) => state.profile);
  const { t } = useTranslation();
  const { language, toggleLanguage, gradient_primary } = useContext(
    themeContext
  );
  const moveHome = useCallback(() => history.replace("/"), [history]);
  useEffect(() => {
    if (isAuth) {
      moveHome();
    }
  }, [isAuth, moveHome]);
  const dispatch = useDispatch();

  const canLogin = () => {
    return !state.username.validity || !state.password.validity;
  };

  const handleChange = (value, validity, name) => {
    setstate({ ...state, [name]: { value, validity } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        actions.login(
          state.username.value,
          state.password.value,
          state.remember.value
        )
      );
      moveHome();
    } catch (err) {
      return;
    }
  };

  return (
    <div className={`${classes.root} ${gradient_primary}`}>
      <div className={classes.centeredCard}>
        <Paper elevation={2} className="p-1 m-1">
          <img
            src={Logo}
            width={100}
            alt="وكائد لتقنية المعلومات - البرنامج المحاسبي السحابي"
          />
          <Typography component="h1" variant="h5">
            {`${t("common:accountlogin")}: POS ${global.STORE_NUMBER}`}
          </Typography>

          <form className={classes.form} noValidate>
            <Box className="py">
              <Input
                getResult={handleChange}
                name="username"
                label={t("settings:username") + " *"}
                initialValue={state.username.value}
                min={2}
                required
                serverError={authStore.errors && " "}
              />
            </Box>
            <Box className="py">
              <Input
                getResult={handleChange}
                name="password"
                label={t("common:password") + " *"}
                type="password"
                initialValue={state.password.value}
                min={5}
                required
                serverError={authStore.errors && " "}
              />
            </Box>
            <Box className="flex flex-start">
              <FormControlLabel
                control={
                  <Checkbox
                    value={state.remember.value}
                    color="primary"
                    onChange={() =>
                      handleChange(!state.remember.value, true, "remember")
                    }
                  />
                }
                label={
                  <Typography variant="caption">
                    {t("common:rememberme")}
                  </Typography>
                }
              />
            </Box>
            {authStore.errors && (
              <Box component="div" className="center">
                <Typography color="error" variant="caption">
                  {t("common:loginerr")}
                </Typography>
              </Box>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
              endIcon={authStore.loading && <ButtonSpinner />}
              disabled={canLogin() || authStore.loading}
            >
              {t("common:login")}
            </Button>
            <Box mt={1}>
              <Link href="#" variant="body2" className="flex flex-start">
                {t("common:forgotpassword")}
              </Link>
            </Box>
          </form>
          <Box className="flex flex-justify-center py">
            <SwitchButtons
              buttons={langBtns}
              state={language}
              onClick={(val) => {
                if (val !== language) toggleLanguage(val);
              }}
            />
          </Box>
        </Paper>
      </div>

      {/* Footer */}
      <AppBar component="footer" color="primary" className={classes.footer}>
        <Container>
          <Grid container className={classes.footerTop}>
            <Grid item md={4} sm={12} className="py">
              <Link href="https://wakaed.sa" target="_blank">
                <Typography
                  variant="caption"
                  className="text-light hoverable-op"
                >
                  {t("common:loginfooterwakaed")}
                </Typography>
              </Link>
            </Grid>
            <Grid
              item
              md={8}
              sm={12}
              className="py flex hor flex-justify-evenly"
            >
              <Typography
                variant="caption"
                className="flex flex-align-center ltr"
              >
                <PhoneAndroidOutlinedIcon fontSize="small" /> +966-50-392-5556
              </Typography>
              <Typography
                variant="caption"
                className="flex flex-align-center ltr"
              >
                <PhoneAndroidOutlinedIcon fontSize="small" /> +96654-392-5556
              </Typography>
              <Typography
                variant="caption"
                className="flex flex-align-center ltr"
              >
                <EmailOutlinedIcon fontSize="small" /> info@wakaed.com
              </Typography>
            </Grid>
          </Grid>
          <Social />
        </Container>
      </AppBar>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    [theme.breakpoints.down("xs")]: {
      // margin: "3rem 0",
    },
  },
  paper: {
    margin: theme.spacing(2, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  footer: {
    bottom: 0,
    top: "auto",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      top: "100%",
      // marginTop: "7rem",
    },
  },
  footerTop: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      textAlign: "center",
    },
  },
  centeredCard: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    width: "100%",
    textAlign: "center",
    maxWidth: 400,
    [theme.breakpoints.down("xs")]: {
      // position: "relative",
      // top: "unset",
      // left: "unset",
      // transform: "none",
      // width: "80%",
      // marginBottom: "7rem",
      // height: "100%",
    },
  },
}));

export default Login;
