export const calculateDiscount = (discount, quantity) => {
  // return (
  //   item.quantity.value * item.unit_price.value * (item.discount.value / 100)
  // );
  return parseFloat(discount * quantity).toFixed(2);
};

const calculateSingleTotal = (quantity, unit_price, discount) => {
  return parseFloat(
    quantity * unit_price - calculateDiscount(discount, quantity)
  ).toFixed(2);
};

export const calculateTaxTotal = (
  quantity,
  unit_price,
  discount,
  tax_value
) => {
  return parseFloat(
    calculateSingleTotal(quantity, unit_price, discount) * (tax_value / 100)
  ).toFixed(2);
};

export const calculateSingleTotalWithTax = (
  quantity,
  unit_price,
  discount,
  tax_value
) => {
  return parseFloat(
    +calculateSingleTotal(quantity, unit_price, discount) +
      +calculateTaxTotal(quantity, unit_price, discount, tax_value)
  ).toFixed(2);
};

export const calculateHelperPercentage = (unit_price, discount) => {
  return parseFloat((discount * 100) / unit_price).toFixed(2);
};
