import React, { useState, useEffect, useCallback, useContext } from "react";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import themeContext from "../../../contexts/theme/themeContext";

const Input = (props) => {
  const {
    variant,
    label,
    placeholder,
    initialValue,
    name,
    required,
    min,
    max,
    email,
    type,
    decimal,
    multiPhone,
    getResult,
    disabled,
    serverError,
    multiline,
    rows,
    setTouched,
    htmlMin,
    htmlMax,
    mathMin,
    mathMax,
    maxErrMsg,
    minErrMsg,
    step,
    withIcon,
    withStartIcon,
    forcedValue,
    onKeyDown,
    inputStyles,
    autoFocus,
    width,
    readOnly,
  } = props;

  const [state, setState] = useState({
    value: initialValue ? initialValue : "",
    touched: false,
    valid: false,
    errorMsg: "",
  });
  const { t } = useTranslation();
  const { language } = useContext(themeContext);
  const showServerErr = useCallback(() => {
    setState({
      ...state,
      valid: false,
      touched: true,
      errorMsg: serverError,
    });
    // eslint-disable-next-line
  }, [serverError]);
  useEffect(() => {
    if (serverError) {
      showServerErr();
    }
  }, [serverError, showServerErr]);

  useEffect(() => {
    if (required && setTouched && state.value === "") {
      setState({ ...state, touched: true });
    }
    // eslint-disable-next-line
  }, [setTouched]);
  useEffect(() => {
    if (forcedValue) {
      handleChange({ target: { value: forcedValue } });
      // setState({
      //   ...state,
      //   value: forcedValue,
      //   touched: true,
      //   valid: !!forcedValue
      // });
    }
    // eslint-disable-next-line
  }, [forcedValue]);
  const handleChange = (e, forced) => {
    let val = e.target.value;
    let isValid = true;
    let errorMsg = "";
    if (email) {
      const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = reg.test(val) && isValid;
      errorMsg = "يرجى التأكد من الإيميل";
    }
    if (decimal) {
      const reg = /^[0-9]+(\.[0-9]*)?$/;
      isValid = reg.test(val) && isValid;
      errorMsg = t("common:onlynumbers");
    }
    if (multiPhone) {
      const reg = /^[+0-9]+(-?[0-9]\/?\+?)+$/;
      isValid = reg.test(val) && isValid;
      errorMsg = "يجب أن يكون رقم هاتف مثل: +966-555-666-777 أو 00966555666777";
    }
    if (min && val.trim().length <= min) {
      isValid = false;
      errorMsg = `يجب ان ﻻ يقل عن ${min + 1} فراغات`;
    }
    if (mathMin && +val <= +mathMin) {
      isValid = false;
      errorMsg = minErrMsg ? minErrMsg : `يجب أن يكون أعلى من ${mathMin}`;
    }
    if (mathMax && +val > +mathMax) {
      isValid = false;
      errorMsg = maxErrMsg ? maxErrMsg : `ﻻ يمكن أن يتعدى ${mathMax}`;
    }
    if (max && val.trim().length >= max) {
      isValid = false;
      errorMsg = `يجب أن ﻻ يزيد عن ${max - 1} فراغات`;
    }
    if (required && val.trim().length <= 0) {
      isValid = false;
      errorMsg = "هذا الفراغ مطلوب";
    }
    setState({ value: val, touched: true, valid: isValid, errorMsg: errorMsg });
    getResult(val.trim(), isValid, name);
  };

  return (
    <TextField
      variant={variant ? variant : "outlined"}
      label={label}
      value={state.value}
      name={name}
      onChange={handleChange}
      disabled={disabled}
      error={state.touched && !state.valid}
      helperText={state.touched && !state.valid && state.errorMsg}
      type={type}
      autoFocus={autoFocus}
      multiline={multiline}
      rows={rows}
      style={{ width: width ? width : "100%" }}
      onKeyDown={onKeyDown}
      inputProps={{
        min: htmlMin,
        max: htmlMax,
        step: step,
        style: {
          ...inputStyles,
          ...((decimal || multiPhone || email) && language === "ar-AR"
            ? inputARstyle
            : ""),
        },
      }}
      InputProps={{
        endAdornment: withIcon ? withIcon : "",
        startAdornment: withStartIcon ? withStartIcon : "",
        placeholder: placeholder ? t(`common:${placeholder}`) : "",
        readOnly: !!readOnly,
        // style:{...inputStyles}
      }}
    />
  );
};

const inputARstyle = {
  direction: "ltr",
  textAlign: "right",
};

export default Input;
