import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import i18n from "../../i18n";

const loading = () => {
  return {
    type: actionTypes.SETTINGS_LOADING,
  };
};

const setErrors = (err) => {
  return async (dispatch) => {
    setTimeout(() => {
      dispatch({ type: actionTypes.SETTINGS_ERRORS, errors: null });
    }, 6000);
    dispatch({
      type: actionTypes.SETTINGS_ERRORS,
      errors: err && err.data ? err.data : err,
    });
  };
};
export const getSalesman = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get("/user/salesman");
      dispatch({ type: actionTypes.SET_SALESMAN, salesman: res.data });
    } catch (err) {
      dispatch(setErrors(err.response));
    }
  };
};

/*Users */
export const getUsers = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "id";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "id";
      orderDirection = query.orderDirection === "asc" ? "-" : "";
    }
    try {
      const res = await axios.get(`/user?sort=${orderDirection + orderQuery}`);
      dispatch({ type: actionTypes.SET_USERS, users: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
    }
  };
};

export const addEditUser = (user, editId) => {
  return async (dispatch) => {
    dispatch(loading());
    let res;
    try {
      if (editId) {
        res = await axios.put("/user/" + user.id, user);
      } else {
        res = await axios.post("/user", user);
      }
      if (!res.data.success) {
        throw res.data.errors ? res.data.errors : res.data;
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/user/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getUserPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`user/permissions`);
      dispatch({
        type: actionTypes.SET_USER_PERMISSIONS,
        userPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
export const saveUserPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`user/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_USER_PERMISSIONS,
          userPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

/*User Groups */
export const getUserGroups = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get("/usergroup");
      dispatch({ type: actionTypes.SET_USERGROUPS, usergroups: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
    }
  };
};

export const addEditUserGroups = (group, editId) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (editId) {
        await axios.put("/usergroup/" + editId, group);
      } else {
        let body = group;
        if (group.role === " ") {
          body = { name: group.name };
        }
        await axios.post("/usergroup", body);
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteUserGroups = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/usergroup/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getUsergroupPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`usergroup/permissions`);
      dispatch({
        type: actionTypes.SET_USERGROUP_PERMISSIONS,
        usergroupPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
export const saveUsergroupPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`usergroup/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_USERGROUP_PERMISSIONS,
          usergroupPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

/*User-Store Links */
export const getUserStore = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    const orderDirection = query.orderDirection === "asc" ? "-" : "";
    const orderQuery = query.orderBy ? query.orderBy.field : "user_id";
    try {
      const res = await axios.get(
        `/userstore?per-page=${query.pageSize}&page=${query.page + 1}&sort=${
          orderDirection + orderQuery
        }`
      );
      dispatch({ type: actionTypes.SET_USERSTORE, userstores: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const addEditUserStoreLink = (link, editId) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (editId) {
        await axios.put(`/userstore/${editId}`, link);
      } else {
        await axios.post("/userstore", link);
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteUserStoreLink = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/userstore/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getUsersList = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`/userstore/userlist`);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getStoresList = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`/userstore/storelist`);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

/*Banks */
export const getBanks = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "id";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "id";
      orderDirection = query.orderDirection === "asc" ? "-" : "";
    }
    try {
      const res = await axios.get(`/bank?sort=${orderDirection + orderQuery}`);
      dispatch({ type: actionTypes.SET_BANKS, banks: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
    }
  };
};

export const addEditBank = (bank, edit) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (edit) {
        await axios.put("/bank/" + bank.id, { ...bank });
        dispatch({ type: actionTypes.UNLOAD });
      } else {
        await axios.post("/bank", { ...bank });
        dispatch({ type: actionTypes.UNLOAD });
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteBank = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/bank/" + id);
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getBankPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`bank/permissions`);
      dispatch({
        type: actionTypes.SET_BANK_PERMISSIONS,
        bankPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
export const saveBankPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`bank/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_BANK_PERMISSIONS,
          bankPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

/*Employees */
export const getEmployees = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "id";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "id";
      orderDirection = query.orderDirection === "asc" ? "-" : "";
    }
    try {
      const res = await axios.get(
        `/employee?per-page=${query.pageSize}&page=${query.page + 1}&sort=${
          orderDirection + orderQuery
        }`
      );
      dispatch({ type: actionTypes.SET_EMPLOYEE, employees: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
    }
  };
};
export const addEditEmployee = (employee, edit) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (edit) {
        await axios.put("/employee/" + employee.id, { ...employee });
        dispatch({ type: actionTypes.UNLOAD });
      } else {
        await axios.post("/employee", { ...employee });
        dispatch({ type: actionTypes.UNLOAD });
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteEmployee = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/employee/" + id);
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getEmployeesPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`employee/permissions`);
      dispatch({
        type: actionTypes.SET_EMPLOYEES_PERMISSIONS,
        employeePermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
export const saveEmployeesPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`employee/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_EMPLOYEES_PERMISSIONS,
          employeePermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

/* Permissions */
export const getPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`user/spermissions`);
      dispatch({ type: actionTypes.SET_PERMISSIONS, permissions: res.data });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
export const editPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`user/spermissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_PERMISSIONS,
          permissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
/*General Settings */
export const editGeneralSetting = (name, body, type) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.put("/setting/" + name, body);
      dispatch({
        type: actionTypes.EDIT_SETTING,
        setting: res.data,
        setType: type,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const uploadLogo = (logo) => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      const data = new FormData();
      data.append("logo", logo);
      await axios.post("/setting/logo", data);

      // dispatch({
      //   type: actionTypes.EDIT_SETTING,
      //   setting: res.data,
      //   setType: type,
      // });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const getSettingByName = (name) => {
  return (dispatch, getState) => {
    const { settings } = getState();

    const set = settings.settings.generals.find((set) => set.name === name);

    let value;
    if (set) {
      value = i18n.language === "ar-AR" ? set.value : set.valueEN;
    }
    return value;
  };
};
