import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { getUnixTime } from "date-fns";
import { fireSnake } from "./globalsnakebar";
import {
  calculateSingleTotalWithTax,
  calculateTaxTotal,
} from "../../util/calculators";
import i18n from "../../i18n";
import { refreshProfileSilently } from "./profile";

const loading = () => {
  return {
    type: actionTypes.SALES_LOADING,
  };
};

const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};

const setErrors = (err) => {
  // return async (dispatch) => {
  //   setTimeout(() => {
  //     dispatch({ type: actionTypes.SALES_ERRORS, errors: null });
  //   }, 6000);
  //   dispatch({
  //     type: actionTypes.SALES_ERRORS,
  //     errors: err && err.data ? err.data : err,
  //   });
  // };
  return {
    type: actionTypes.SALES_ERRORS,
    errors: err && err.data ? err.data : err,
  };
};
let timeout;
/*Sale Returns (Resales) */
export const getResaleInvoice = (id = 0) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(
        `/resales/${id}?expand=items,customer,saledBy,store`
      );
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getResaleInvoices = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    const orderDirection = query.orderDirection === "asc" ? "" : "-";
    const orderQuery = query.orderBy ? query.orderBy.field : "id";
    try {
      const res = await axios.get(
        `/resales?expand=items,customer,saledBy,store&per-page=${
          query.pageSize
        }&page=${query.page + 1}&sort=${orderDirection + orderQuery}`
      );
      dispatch({ type: actionTypes.SET_RESALES, resales: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteResaleInvoice = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete(`/resales/${id}`);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const searchSoldInvoices = (storeId, query) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    clearTimeout(timeout);
    if (!query) {
      return dispatch(clearResalesInvoices());
    }
    timeout = setTimeout(async () => {
      try {
        const res = await axios.get(
          `/resales/invoicelist?store_id=${storeId}&q=${query ? query : ""}`
        );
        dispatch({
          type: actionTypes.SET_RESALES_INV_LIST,
          searchedResales: res.data,
        });
      } catch (err) {
        const error = err.response ? err.response.data : err;
        dispatch(setErrors(error));
        dispatch(GeneralFiresnake(error));
        throw error;
      } finally {
        dispatch({ type: actionTypes.UNLOAD });
      }
    }, 1500);
  };
};
export const clearResalesInvoices = () => {
  return {
    type: actionTypes.SET_RESALES_INV_LIST,
    searchedResales: [],
  };
};

export const searchSoldInvoiceProducts = (invoiceId, query) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      const res = await axios.get(
        `/resales/plist?invoice_id=${invoiceId}&q=${query ? query : ""}`
      );

      return res.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
      throw error;
    } finally {
      dispatch({
        type: actionTypes.UNLOAD,
      });
    }
  };
};

export const addResaleItem = (invoiceInfo, productInfo, selected) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      if (!selected) {
        //New draft
        const draftBody = { status: 0, ...getReturnInvoiceValues(invoiceInfo) };
        const draft = await axios.post(
          `/resales?expand=items,customer,saledBy,store`,
          draftBody
        );

        const itemBody = {
          invoice_id: draft.data.id,
          ...getReturnProductValues(productInfo),
        };

        const item = await axios.post(`/resalesitem`, itemBody);
        dispatch({ type: actionTypes.UNLOAD });
        return { ...draft.data, items: [...draft.data.items, item.data] };
      } else {
        //Existing draft
        const itemBody = {
          invoice_id: selected.id,
          ...getReturnProductValues(productInfo),
        };

        const item = await axios.post(`/resalesitem`, itemBody);
        dispatch({ type: actionTypes.UNLOAD });
        return { ...selected, items: [...selected.items, item.data] };
      }
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const editResaleItem = (state, item) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      let body;
      if (state.product.value.item_id) {
        //product was change
        body = {
          ...getReturnProductValues(state),
        };
      } else {
        //only quantity changed

        const quantity = state.quantity.value;
        body = {
          quantity: quantity,
          discount: (+item.discount / +item.quantity) * quantity,
          tax_total: (+item.tax_total / +item.quantity) * quantity,
          total: (+item.total / +item.quantity) * quantity,
        };
      }
      const editedItem = await axios.put(`/resalesitem/${item.id}`, body);
      dispatch({ type: actionTypes.UNLOAD });
      return editedItem.data;
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const deleteResaleItem = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      await axios.delete(`/resalesitem/${id}`);
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveResaleInvoice = (invoiceInfo, totals, type, id) => {
  //type = 0 draft & type = 1 is exported
  return async (dispatch) => {
    dispatch(loading());
    try {
      const InvoiceInfo = {
        status: type,
        ...getReturnInvoiceValues(invoiceInfo),
        ...totals,
        //brutto_no_tax:0 //TODO This was told its for later
      };
      console.log(InvoiceInfo);
      await axios.put(`/resales/${id}`, InvoiceInfo);
      //If saved succes refresh Cash
      dispatch(refreshProfileSilently());
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
    }
  };
};

/*Customer related actions*/
export const addFastCustomer = (name) => {
  return async (dispatch, getState) => {
    dispatch(loading());
    try {
      const { profile } = getState();
      const res = await axios.post("/cashcustomer", { name: name });
      dispatch({ type: actionTypes.UNLOAD });
      return { ...res.data, salesman_name: profile.user_name };
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

//Helpers

const getReturnInvoiceValues = (state) => {
  const {
    b_invoice_nr,
    store_id,
    customer_id,
    invoice_billing_date,
    saled_by,
  } = state.invoice.value;

  const ifCash = () =>
    state.paid_type.value === "cash" || state.paid_type.value === "postpay";

  return {
    b_invoice_nr: b_invoice_nr,
    store_id: store_id,
    customer_id: customer_id,
    invoice_billing_date: +invoice_billing_date,
    invoice_date: getUnixTime(state.invoice_date.value),
    paid_type: state.paid_type.value,
    check_nr: state.check_nr.value,
    check_date: ifCash() ? "" : getUnixTime(state.check_date.value),
    bank_id: ifCash() ? "" : state.bank_id.value,
    description: state.description.value,
    saled_by: saled_by,
  };
};

const getReturnProductValues = (state) => {
  const {
    item_id,
    product_id,
    unit_price,
    tax_id,
    tax_value,
    discount,
    quantity,
  } = state.product.value;
  const quantityToReturn = state.quantity.value;

  // getting discount per quantity from the totalDiscount/totalQuantity
  const reversedDiscount = +discount / +quantity;

  const TaxTotal = calculateTaxTotal(
    quantityToReturn,
    unit_price,
    reversedDiscount,
    tax_value
  );

  const SingleTotalWithTax = calculateSingleTotalWithTax(
    quantityToReturn,
    unit_price,
    reversedDiscount,
    tax_value
  );

  return {
    item_id: item_id,
    product_id: product_id,
    tax_id: tax_id,
    quantity: quantityToReturn,
    unit_price: unit_price,
    discount: reversedDiscount * quantityToReturn,
    tax_total: TaxTotal,
    total: SingleTotalWithTax,
  };
};
const GeneralFiresnake = (err) => {
  return (dispatch) => {
    if (err.status === 401 || err.status === 403) {
      return dispatch(fireSnake("error", i18n.t("common:unaothurized")));
    }
    if (err.status === 500) {
      return dispatch(fireSnake("warning", "خطأ كود 500"));
    }
    dispatch(fireSnake("error", err.message || i18n.t("common:uncommonerror")));
  };
};
