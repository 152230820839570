import { combineReducers } from "redux";
import authReducer from "./auth";
import profileReducer from "./profile";
import salesReducer from "./sales";
import settingsReducer from "./settings";
import snakebarReducer from "./globalsnakebar";
import posReducer from "./pos";

export default combineReducers({
  auth: authReducer,
  profile: profileReducer,
  sales: salesReducer,
  settings: settingsReducer,
  snakebar: snakebarReducer,
  pos: posReducer,
});
