//Stores & Inventories
export const STORES_LOADING = "STORES_LOADING";
export const SET_STORES = "SET_STORES";
export const SET_TRANSFERS = "SET_TRANSFERS";
export const STORE_ERROR = "STORE_ERROR";
export const SET_STORE_PERMISSIONS = "SET_STORE_PERMISSIONS";
export const SET_TRANSFERS_PERMISSIONS = "SET_TRANSFERS_PERMISSIONS";

//Snakebar
export const FIRE_SNAKEBAR = "FIRE_SNAKEBAR";
export const CLEAR_SNAKEBAR = "CLEAR_SNAKEBAR";

//Auth
export const AUTH_LOADING = "AUTH_LOADING";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const AUTH_ERRORS = "AUTH_ERRORS";

//Profile
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_ERRORS = "PROFILE_ERRORS";

//Sales (INVOICES) & Customers
export const ALL_CUSTOMERS_SEARCH = "ALL_CUSTOMERS_SEARCH";
export const SALE_PRODUCT_SEARCH = "SALE_PRODUCT_SEARCH";
export const SET_SALES = "SET_SALES";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SALES_LOADING = "SALES_LOADING";
export const SALES_ERRORS = "SALES_ERRORS";
export const SET_CUSTOMER_PERMISSIONS = "SET_CUSTOMER_PERMISSIONS";
export const SET_SALES_PERMISSIONS = "SET_SALES_PERMISSIONS";
export const SET_RESALES_PERMISSIONS = "SET_RESALES_PERMISSIONS";
export const SET_RESALES = "SET_RESALES";
export const SET_RESALES_INV_LIST = "SET_RESALES_INV_LIST";

//Purchases
export const PURCHASES_LOADING = "PURCHASES_LOADING";
export const PURCHASES_ERRORS = "PURCHASES_ERRORS";
export const SET_PURCHASES = "SET_PURCHASES";
export const SET_REPURCHASES = "SET_REPURCHASES";
export const SET_SUPPLIERS = "SET_SUPPLIERS";
export const ALL_PRODUCT_SEARCH = "ALL_PRODUCT_SEARCH";
export const ALL_SUPPLIERS_SEARCH = "ALL_SUPPLIERS_SEARCH";
export const SET_SUPPLIER_PERMISSIONS = "SET_SUPPLIER_PERMISSIONS";
export const SET_PURCHASE_PERMISSIONS = "SET_PURCHASE_PERMISSIONS";
export const SET_REPURCHASE_PERMISSIONS = "SET_REPURCHASE_PERMISSIONS";
export const SET_REPURCHASE_INV_LIST = "SET_REPURCHASE_INV_LIST";

//Products
export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const PRODUCTS_ERRORS = "PRODUCTS_ERRORS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_GROUPS = "SET_GROUPS";
export const SET_UNITS = "SET_UNITS";
export const SET_MANUFACTURERS = "SET_MANUFACTURERS";
export const SET_TAXS = "SET_TAXS";
export const SET_UNIT_PERMISSIONS = "SET_UNIT_PERMISSIONS";
export const SET_TAX_PERMISSIONS = "SET_TAX_PERMISSIONS";
export const SET_BANK_PERMISSIONS = "SET_BANK_PERMISSIONS";
export const SET_PRODUCTS_PERMISSIONS = "SET_PRODUCTS_PERMISSIONS";
export const SET_PRODUCT_GROUP_PERMISSIONS = "SET_PRODUCT_GROUP_PERMISSIONS";

//Expenses
export const EXPENSES_LOADING = "EXPENSES_LOADING";
export const EXPENSES_ERRORS = "EXPENSES_ERRORS";
export const SET_EXPENSE = "SET_EXPENSE";
export const SET_EXPENSE_GROUPS = "SET_EXPENSE_GROUPS";
export const SET_EXPENSES_PERMISSIONS = "SET_EXPENSES_PERMISSIONS";
export const SET_EXPENSES_GROUP_PERMISSIONS = "SET_EXPENSES_GROUP_PERMISSIONS";
export const SET_CLIST = "SET_CLIST";
export const SET_ELIST = "SET_ELIST";

//Receipts
export const VOUCHERS_LOADING = "VOUCHERS_LOADING";
export const VOUCHERS_ERRORS = "VOUCHERS_ERRORS";
export const SET_RECEIPTS = "SET_RECEIPTS";
export const SET_PAYMENTS = "SET_PAYMENTS";
export const SET_RECEIPTS_PERMISSIONS = "SET_RECEIPTS_PERMISSIONS";
export const SET_PAYMENTS_PERMISSIONS = "SET_PAYMENTS_PERMISSIONS";
export const SET_RECEIPT_RECEIVERS = "SET_RECEIPT_RECEIVERS";
export const SET_PAYMENT_RECEIVERS = "SET_PAYMENT_RECEIVERS";

//Settings
export const SETTINGS_LOADING = "SETTINGS_LOADING";
export const SETTINGS_ERRORS = "SETTINGS_ERRORS";
export const SET_SETTINGS = "SET_SETTINGS";
export const EDIT_SETTING = "EDIT_SETTING";
export const SET_EMPLOYEE = "SET_EMPLOYEE";
export const SET_USERS = "SET_USERS";
export const SET_USERSTORE = "SET_USERSTORE";
export const SET_USERGROUPS = "SET_USERGROUPS";
export const SET_BANKS = "SET_BANKS";
export const SET_SALESMAN = "SET_SALESMAN";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_EMPLOYEES_PERMISSIONS = "SET_EMPLOYEES_PERMISSIONS";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const SET_USERGROUP_PERMISSIONS = "SET_USERGROUP_PERMISSIONS";

//Reports
export const REPORTS_LOADING = "REPORTS_LOADING";
export const REPORTS_ERRORS = "REPORTS_ERRORS";
export const SET_JOURNALS = "SET_JOURNALS";
export const SET_JOURNALS_PERMISSIONS = "SET_JOURNALS_PERMISSIONS";
export const SET_ACC_TREE = "SET_ACC_TREE";
export const SET_TAXATIONS = "SET_TAXATIONS";
export const SET_REPORTS_PERMISSIONS = "SET_REPORTS_PERMISSIONS";

//Offers
export const OFFERS_LOADING = "OFFERS_LOADING";
export const OFFERS_ERRORS = "OFFERS_ERRORS";
export const SET_OFFERS = "SET_OFFERS";
export const OFFERS_CUSTOMERS_SEARCH = "OFFERS_CUSTOMERS_SEARCH";
export const OFFERS_PRODUCT_SEARCH = "OFFERS_PRODUCT_SEARCH";
export const SET_OFFERS_PERMISSIONS = "SET_OFFERS_PERMISSIONS";

//Globals
export const ITEM_LOADING = "ITEM_LOADING";
export const UNLOAD = "UNLOAD"; //solve non-table-refetching process (saleitems/purchaseitems)

//POS
export const POS_LOADING = "POS_LOADING";
export const POS_ERRORS = "POS_ERRORS";
export const SET_GROUPS_POS = "SET_GROUPS_POS";
export const SET_GROUP_PRODUCTS_POS = "SET_GROUP_PRODUCTS_POS";
