import * as actionTypes from "../actions/types";

const initialState = {
  loading: false,
  groups: [],
  products: [],
  itemLoading: false,
  errors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionTypes.ITEM_LOADING:
      return {
        ...state,
        itemLoading: true,
        errors: null,
      };
    case actionTypes.SET_GROUPS_POS:
      return {
        ...state,
        groups: action.groups,
        itemLoading: false,
      };
    case actionTypes.SET_GROUP_PRODUCTS_POS:
      return {
        ...state,
        products: action.products,
        itemLoading: false,
      };
    case actionTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };

    case actionTypes.POS_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };

    default:
      return state;
  }
};
