import React from "react";
import { Container } from "@material-ui/core";
import MainMenu from "./components/MainMenu/MainMenu";
import TopCard from "./components/Cards/TopCards";
import Layout from "../../components/Layout/Layout";
// import Loader from "../Loader";

const Home = () => {
  // const classes = useStyle();

  return (
    <Layout>
      <Container>
        <TopCard />
        <MainMenu />
      </Container>
    </Layout>
  );
};

// const useStyle = makeStyles({
// root: {
//   height: "100vh",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   background: "#bbb"
// }
// });

export default Home;
