import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { getUnixTime } from "date-fns";
import { fireSnake } from "./globalsnakebar";
import {
  calculateDiscount,
  calculateSingleTotalWithTax,
  calculateTaxTotal,
} from "../../util/calculators";
import i18n from "../../i18n";
import { refreshProfileSilently } from "./profile";

const loading = () => {
  return {
    type: actionTypes.POS_LOADING,
  };
};

const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};

const setErrors = (err) => {
  return {
    type: actionTypes.POS_ERRORS,
    errors: err && err.data ? err.data : err,
  };
};

export const getProductByBarcode = (search, storeId, customer_id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    let query = `?q=${search}&store_id=${storeId}&customer_id=${customer_id}`;
    if (!customer_id) {
      query = `?q=${search}&store_id=${storeId}`;
    }
    try {
      const res = await axios.get(`/sales/pblist${query}`);

      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const getGroupsPOS = (parentId) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      const res = await axios.get(`/sales/glist?parent_id=${parentId}`);
      dispatch({ type: actionTypes.SET_GROUPS_POS, groups: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getGroupProductsPOS = (storeId, groupId) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      const res = await axios.get(
        `sales/pglist?store_id=${storeId}&product_group_id=${groupId}`
      );
      dispatch({
        type: actionTypes.SET_GROUP_PRODUCTS_POS,
        products: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const addSaleItemPOS = (customerInfo, productInfo, selected) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      if (!selected) {
        //New draft
        const draftBody = {
          status: 0,
          ...getInvoiceValues({
            ...customerInfo,
            store_id: productInfo.store_id,
          }),
        };
        const draft = await axios.post(
          `/sales?expand=customer,items,saledBy,repairedBy,store`,
          draftBody
        );

        const itemBody = {
          invoice_id: draft.data.id,
          ...getProductValuesAdd(productInfo),
        };
        const item = await axios.post(`/salesitem`, itemBody);

        return { ...draft.data, items: [...draft.data.items, item.data] };
      } else {
        //Existing draft
        const itemBody = {
          invoice_id: selected.id,
          ...getProductValuesAdd(productInfo),
        };
        const item = await axios.post(`/salesitem`, itemBody);

        return { ...selected, items: [...selected.items, item.data] };
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const editSaleItemPOS = (productInfo, id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      const editedItem = await axios.put(
        `/salesitem/${id}`,
        getProductValuesEdit(productInfo)
      );
      return editedItem.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteSalesItemPOS = (id) => {
  return async (dispatch) => {
    try {
      await axios.delete(`/salesitem/${id}`);
      return id;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

let timeout;
export const searchCustomersPOS = (query) => {
  return async (dispatch) => {
    clearTimeout(timeout);
    try {
      let promise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(axios.get(`/sales/clist?q=${query ? query : ""}`));
        }, 1000);
      });
      let res = await promise;
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      dispatch(GeneralFiresnake(err.response ? err.response.data : err));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveSaleInvoicePOS = (customerInfo, totals, type, id) => {
  //type = 0 draft | type = 1 export
  return async (dispatch) => {
    dispatch(loading());
    try {
      const InvoiceInfo = {
        status: type,
        ...getInvoiceValues(customerInfo),
        ...totals,
        //brutto_no_tax:0 //TODO This was told its for later
      };

      const res = await axios.put(
        `/sales/${id}?expand=customer,items,saledBy,repairedBy,store`,
        InvoiceInfo
      );
      //If saved succes refresh Cash
      dispatch(refreshProfileSilently());
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteSaleInvoicePOS = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("sales/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
// Invoices
export const getDraftInvoicesPOS = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "invoice_date";
    let orderDirection = "-";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "invoice_date";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/sales?expand=customer,items,saledBy,repairedBy,store&per-page=${
          query.pageSize
        }&page=${query.page + 1}&sort=${orderDirection + orderQuery}`
      );
      //This is a manual filter
      const drafts = res.data.sales.filter((s) => s.status === 0);
      return {
        sales: drafts,
        pagination: res.data.pagination,
      };
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getExportedInvoicesPOS = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "invoice_date";
    let orderDirection = "-";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "invoice_date";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/sales?expand=customer,items,saledBy,repairedBy,store&per-page=${
          query.pageSize
        }&page=${query.page + 1}&sort=${orderDirection + orderQuery}`
      );
      //This is a manual filter
      const invoices = res.data.sales.filter((s) => s.status === 3);
      return {
        sales: invoices,
        pagination: res.data.pagination,
      };
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

/**
 * Helpers
 */
const getInvoiceValues = (invoiceInfo) => {
  const checkPaymentCash = () =>
    invoiceInfo.paid_type.value === "cash" ||
    invoiceInfo.paid_type.value === "postpay";
  return {
    customer_id: invoiceInfo.customer.value
      ? invoiceInfo.customer.value.id
      : "",
    store_id: invoiceInfo.store_id.value,
    saled_by: invoiceInfo.customer.value
      ? invoiceInfo.customer.value.salesman
      : "",
    invoice_date: getUnixTime(invoiceInfo.invoice_date.value),
    h_invoice_nr: invoiceInfo.h_invoice_nr.value,
    paid_type: invoiceInfo.paid_type.value,
    check_nr: invoiceInfo.check_nr.value,
    check_date: checkPaymentCash()
      ? ""
      : getUnixTime(invoiceInfo.check_date.value),
    bank_id: checkPaymentCash() ? "" : invoiceInfo.bank_id.value,
    description: invoiceInfo.description.value,
    deposit_amount: invoiceInfo.deposit_amount.value,
    rest_amount: invoiceInfo.rest_amount.value,
  };
};

const getProductValuesEdit = (item) => {
  const discount = calculateDiscount(item.discount, item.quantity);
  const SingleTotalWithTax = calculateSingleTotalWithTax(
    item.quantity,
    item.unit_price,
    item.discount,
    item.tax_value
  );
  const TaxTotal = calculateTaxTotal(
    item.quantity,
    item.unit_price,
    item.discount,
    item.tax_value
  );
  return {
    title: item.title,
    title_state: item.title_state,
    product_id: item.product_id,
    quantity: item.quantity,
    price_type: item.price_type,
    unit_price: item.unit_price,
    tax_id: item.tax_id,
    tax_total: TaxTotal,
    discount: discount,
    total: SingleTotalWithTax,
  };
};
const getProductValuesAdd = (item) => {
  const discount = calculateDiscount(item.discount.value, item.quantity.value);
  const SingleTotalWithTax = calculateSingleTotalWithTax(
    item.quantity.value,
    item.unit_price.value,
    item.discount.value,
    item.product.value.tax_value
  );
  const TaxTotal = calculateTaxTotal(
    item.quantity.value,
    item.unit_price.value,
    item.discount.value,
    item.product.value.tax_value
  );
  return {
    title: item.title.value,
    title_state: item.title_state.value,
    product_id: item.product.value.id,
    quantity: item.quantity.value,
    price_type: item.price_type.value,
    unit_price: item.unit_price.value,
    tax_id: item.product.value.tax_id,
    tax_total: TaxTotal,
    discount: discount,
    total: SingleTotalWithTax,
  };
};
const GeneralFiresnake = (err) => {
  return (dispatch) => {
    if (err.status === 401 || err.status === 403) {
      return dispatch(fireSnake("error", i18n.t("common:unaothurized")));
    }
    if (err.status === 500) {
      return dispatch(fireSnake("warning", "خطأ كود 500"));
    }
    dispatch(fireSnake("error", err.message || i18n.t("common:uncommonerror")));
  };
};
